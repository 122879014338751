.u-w-10 {  width:  10% !important; }
.u-w-20 {  width:  20% !important; }
.u-w-25 {  width:  25% !important; }
.u-w-30 {  width:  30% !important; }
.u-w-33 {  width:  33% !important; }
.u-w-34 {  width:  34% !important; }
.u-w-40 {  width:  40% !important; }
.u-w-50 {  width:  50% !important; }
.u-w-60 {  width:  60% !important; }
.u-w-70 {  width:  70% !important; }
.u-w-75 {  width:  75% !important; }
.u-w-80 {  width:  80% !important; }
.u-w-90 {  width:  90% !important; }
.u-w-100 { width: 100% !important; }

.u-max-w-10 {  max-width:  10ch !important; }
.u-max-w-20 {  max-width:  20ch !important; }
.u-max-w-25 {  max-width:  25ch !important; }
.u-max-w-30 {  max-width:  30ch !important; }
.u-max-w-33 {  max-width:  33ch !important; }
.u-max-w-34 {  max-width:  34ch !important; }
.u-max-w-40 {  max-width:  40ch !important; }
.u-max-w-50 {  max-width:  50ch !important; }
.u-max-w-60 {  max-width:  60ch !important; }
.u-max-w-70 {  max-width:  70ch !important; }
.u-max-w-75 {  max-width:  75ch !important; }
.u-max-w-80 {  max-width:  80ch !important; }
.u-max-w-90 {  max-width:  90ch !important; }
.u-max-w-100 { max-width: 100ch !important; }