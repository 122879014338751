.c-location-grid {
    max-width: 1060px;
    margin: 0 auto;
}

.c-location {
    flex: 1;

    svg {
        max-width: rem(24);
    }

    .c-location__name + p {
        max-width: 30ch;
    }
}

.c-location__area {
    border-bottom: 2px solid #7090C1;
    padding-bottom: rem(6);
    text-align: left;
}

    .c-location__address,
    .c-location__phone {
        display: flex;
        gap: var(--space-unit);
        text-align: left;
        align-items: start;
    }

    .c-location__wrapper {
        display: flex;
        gap: var(--space-unit);
    }

    .c-location-ribbon-img {
        display: block;
        background-size: contain;
        overflow: hidden;
        border-radius: 10px;
        width: 100%;
        max-width: 1060px;
        margin-left: auto;
        margin-right: auto;
    }