///*------------------------------------*\
//    #MIXINS
//\*------------------------------------*/

// Global mixins available to the entire project. Define file-specific
// mixins inside the file to which they relate.

//* -------------------------------------------------------------------------- */
// Font families
@mixin font-serif {
    font-family: var(--font-serif);
}

@mixin font-sans {
    font-family: var(--font-sans);
}

// Type presets
@mixin subtitle {
    font-size: rem(21);
    font-weight: 500;
    line-height: 1.3;
    
  @media (min-width: 700px) {
        font-size: rem(28);
    }
}

@mixin title {
    font-size: rem(42);
    font-weight: 900;
    line-height: 1.2;
    
    @media (min-width: 700px) {
        font-size: rem(52);
        max-width: 36ch;
    }
}

@mixin heading-serif-1 {
    @include font-serif;
    font-size: rem(72);
    font-weight: 900;
    line-height: 1.2;
}

@mixin heading-2 {
    font-size: rem(48);
    line-height: 1;

    @media (min-width: 700px) {
        font-size: rem(56);
    }
}


@mixin body-serif-1 {
    @include font-serif;
    font-size: rem(26);
}

@mixin body-serif-2 {
    @include font-serif;
    font-size: rem(23);
}

@mixin body-serif-3 {
    @include font-serif;
    font-size: rem(20);
}

@mixin body-serif-4 {
    @include font-serif;
    font-size: rem(18);
}

@mixin body-serif-5 {
    @include font-serif;
    font-size: rem(16);
}

@mixin body-serif-6 {
    @include font-serif;
    font-size: rem(14);
}

@mixin body-serif-7 {
    @include font-serif;
    font-size: rem(13);
}

@mixin body-sans-1 {
    @include font-sans;
    font-size: rem(26);
    line-height: 1.3;
}

@mixin body-sans-2 {
    @include font-sans;
    font-size: rem(23);
    line-height: 1.3;
}

@mixin body-sans-3 {
    @include font-sans;
    font-size: rem(20);
    line-height: 1.6;
}

@mixin body-sans-4 {
    @include font-sans;
    font-size: rem(18);
}

@mixin body-sans-5 {
    @include font-sans;
    font-size: rem(16);
}

@mixin body-sans-6 {
    @include font-sans;
    font-size: rem(14);
}

@mixin body-sans-7 {
    @include font-sans;
    font-size: rem(13);
}

@mixin quote {
    @include font-sans;
    font-weight: 900;
    font-size: rem(28);
}


/* -------------------------------------------------------------------------- */


// Generate `:hover` and `:focus` styles in one go.
@mixin hocus() {
  &:hover,
  &:focus {
    @content;
  }
}

// Generate `.is-active, `:hover` and `:focus` styles in one go.
@mixin acthocus() {
  &.is-active,
  &:active,
  &:hover,
  &:focus {
    @content;
  }
}

// Grid item to span all columns
@mixin span-all-columns() {
   grid-column: 1/-1;
   flex-basis: 100%;
   width: 100%;
   max-width: 100%;
}