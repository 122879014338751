/* ==========================================================================
   #Section classes
   ========================================================================== */

.o-section {
  padding-top: rem(24);
  padding-bottom: rem(24);
  
  @include respond-above(sm) {
  }

  @include respond-above(md) {
    padding-top: rem(56);
    padding-bottom: rem(56);
  }

  @include respond-above(lg) {
  }

  @include respond-above(xl) {
    padding-top: rem(80);
    padding-bottom: rem(80);
  }
}

.o-section--subtle-grey {
  background-color: var(--color-brand-grey-200);
}

.o-section--accent {
  background-color: var(--color-brand-blue-700);
  color: var(--color-white);
}

.o-section--accent-light {
  background-color: #DAE4F4;
}

.o-section--dark {
  background-color: #233B4B;
  color: var(--color-white);
}