// Flex alignments
.u-justify-start {
  justify-content: flex-start;
}

.u-justify-end {
  justify-content: flex-end;
}

.u-justify-between {
  justify-content: space-between;
}

.u-justify-center {
  justify-content: center;
}

.u-align-items-start {
  align-items: flex-start;
}

.u-align-items-center {
  align-items: center;
}

.u-align-items-end {
  align-items: flex-end;
}

.u-align-self-center {
  align-self: center;
}

.u-flex-column {
  flex-direction: column;
}

.u-flex-column-reverse {
  flex-direction: column-reverse;
}

.u-flex-row {
  flex-direction: row;
}

.u-flex-row-reverse {
  flex-direction: row-reverse;
}

.u-flex-wrap {
  flex-wrap: wrap;
}

.u-reverse-mobile {
  > * {
    order: 1;

    + * {
      order: -1;
    }
  }
}

  @each $breakpoint in $ss-breakpoints {
    $breakpoint-name: nth($breakpoint, 1);

    @include ss-mq($breakpoint-name) {
      .u-reverse-mobile\@#{$breakpoint-name} {
        > * {
          order: 1;

          + * {
            order: -1;
          }
        }
      }
    }
  }

    @each $breakpoint in $ss-breakpoints {
    $breakpoint-name: nth($breakpoint, 1);

    @include ss-mq($breakpoint-name) {
      .u-remove-reverse-mobile\@#{$breakpoint-name} {
        > * {
          order: 0;

          + * {
            order: 1;
          }
        }
      }
    }
  }