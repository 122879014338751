.c-footer {
    background-color: var(--color-brand-blue-700);
    color: var(--color-footer-text);

    @include respond-above(sm) {

    }


    a {
        text-decoration: none;

        @include hocus() {
            color: var(--color-white);
            text-decoration-color: var(--color-white);
            text-decoration: underline;
        }
    }
}

    .c-footer__nav-sections {
        list-style-type: none;
        padding: 0;
        min-width: rem(200);
    }

    .c-footer__content {
        display: flex;
        flex-direction: column;
        gap: var(--space-unit);

        @media (min-width: 980px) {
            flex-direction: row;
            justify-content: space-between;
        }
    }