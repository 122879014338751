/**
 * DM Sans
 *
*/


  @import url('https://fonts.googleapis.com/css2?family=DM+Sans:opsz,wght@9..40,200;9..40,400;9..40,500;9..40,700&display=swap');

:root {
  // font family
    --font-sans: 'DM Sans', system-ui,-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
    --font-serif: Georgia, serif;
  
    // font size
    --text-base-size: 1em; // body font-size
    --text-scale-ratio: 1.2; // multiplier used to generate the type scale values 👇
    
    // line-height
    --body-line-height: 1.4;
    --heading-line-height: 1.2;
    
  
    // unit - don't modify unless you want to change the typography unit (e.g., from Em to Rem units)
    --text-unit: 1em; // if not Em units → --text-unit: var(--text-base-size);
}