.o-img {
    border-radius: var(--space-xs);
    object-fit: contain;
}

.o-img--shadow {
    box-shadow: 0 8px 8px 0 rgba(0, 0, 0, 0.3);
}

.o-img--cover {
    border-radius: var(--space-xs);
    object-fit: cover;
}

.o-img--no-borders {
    border-radius: 0;
}

.o-img--placeholder {
    background-color: #CCC;
    height: rem(280);
    width: 100%;
}

.o-img--hero {
//  max-height: rem(300);
    object-fit: cover;
}