.c-icon-list {
    padding: 0;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    gap: var(--space-lg);
    max-width: rem(1000);

    @media (min-width: 600px) {
        flex-direction: row;
    }

    & + div img {
        max-height: rem(400);
    }
}

    .c-icon-list__item {
        text-align: center;
        gap: var(--space-unit);
        list-style: none;
    }