.c-core-service--section {

    &:nth-child(odd) {
        .c-core-service {
            flex-direction: row-reverse;
        }
    }

    &:nth-child(4) {
        .c-core-service__offerings {
            column-count: 1;
            
            ul {
                list-style-type: '–  ';
            }
        }
    }
}

.c-core-service {
    gap: var(--space-xl);
    
    @include respond-above(md) {
        display: flex;
        align-items: center;
        justify-content: center;
    }
}
    .c-core-service__content {
        max-width: 600px;
    }

    .c-core-service__offerings-heading {
        text-transform: uppercase;
        letter-spacing: 1px;
        font-size: rem(14);
    }

    .c-core-service__offerings {
        color: var(--color-brand-blue-700);
        opacity: .8;
        padding: 0;
        padding-left: 14px;

        @include respond-above(xl) {
            column-count: 2;
            column-gap: var(--space-md);

            > li {
                padding: 6px 0;
            }
        }
    }