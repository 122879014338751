.c-single-post {
    max-width: 1000px;
    line-height: 1.3;
    padding: 2rem 1rem 6rem;

    .post-title {
        font-size: 54px;

        @media (max-width: 700px) {
            font-size: 36px;
        }
    }

    .post-subtext {
        font-size: 24px;
        text-transform: uppercase;
        letter-spacing: .05em;
        font-weight: 500;
        color: var(--color-brand-light-blue);
        margin-bottom: 1rem;
        line-height: 1.4;

        @media (max-width: 700px) {
            font-size: 21px;
        }
    }
    
    p {
        font-size: 21px;
        line-height: 1.5;
        max-width: none;

        @media (max-width: 700px) {
            font-size: 18px;
        }

        & + p {
            margin-top: 2rem;
        }

        & + h2 {
            margin-top: 3rem;
        }

        & + h3 {
            margin: 1.5rem 0 .5rem;
        }
    }

    h2 {
        margin: 1rem 0 .5rem;
    }

    table {
  margin: 0 auto;
}

    table {
        color: #1f1f1f;
        background: white;
        border: 1px solid grey;
        font-size: 12pt;
        border-collapse: collapse;
        margin: 2rem 0 .5rem;
        width: 100%;

        & + p {
            margin-bottom: 1.5rem;
        }
    }
    table thead th,
    table tfoot th {
        color: #777;
        background: rgba(0,0,0,.1);
        text-align: left;
    }
    table caption {
        padding:.5em;
    }
    table th,
    table td {
        padding: .5em;
        border: 1px solid lightgrey;
    }

    .image-figure {
        margin: 1.5rem 0;
    }
    
    .image-caption {
        opacity: .7;
        margin-top: 8px;
    }
}

.c-single-post__meta {
    display: flex;
    flex-wrap: wrap;
    gap: .5rem;
    justify-content: space-between;
    padding: .5rem;
    color: var(--color-grey-700);
    background-color: var(--color-grey-200);
    margin: .5rem 0;
}