.c-hero-section {
    background: url("/imgs/rts-plant-digital-hero.jpg") no-repeat bottom center;
    background-size: cover;
    position: relative;
    min-height: 800px;
    padding-top: 2rem;

    @media (max-width: 700px) {
        min-height: auto;
    }

    &::before {
        content: '';
        position: absolute;
        top: 0; left: 0; right: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(180deg, rgba(0, 8, 32, 0.8) 0%, rgba(0, 38, 62, 0) 38.7%);

        @media (max-width: 700px) {
            background: rgba(0,8,32,0.5);
        }
    }

    h1, p {
        color: white;
        position: relative;
        z-index: 10;
        text-shadow: 0 2px 6px rgba(0,0,0,.5);
    }

    .c-btn {
        position: relative;
        z-index: 10;
        background-color: white;
        border-color: white;
        color: var(--color-brand-blue-800);
        box-shadow: 0 2px 6px rgba(0,0,0,.5) !important;
    }
}