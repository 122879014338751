
// Provisional while they get new profile photos
.c-team-member {
    background: white;
    box-shadow: 0 2px 4px rgba(0,0,0,.2);
    border-radius: 6px;
    min-height: 140px;
    padding: 1rem;
    margin-top: 0;

    > div {
        margin: auto;
    }
}

.c-team-member .c-team-member__img {
    //display: none;
    margin-bottom: 1rem;
}
// Provisional ends

.c-team-member__img {
    border-radius: var(--space-xs);
    object-fit: cover;
    max-width: 240px;
    height: rem(300);
    margin: 0 auto;
}

.c-team-member__name {
    font-size: rem(22);
    color: var(--color-brand-blue-700);
    line-height: 1;
}

.c-team-member__role {
    font-size: rem(14);
    color: var(--color-grey-700);
    text-transform: uppercase;
    letter-spacing: 1px;
    line-height: 1.4;
    display: inline-block;
}

.c-team-members {
    margin-left: auto;
    margin-right: auto;
}