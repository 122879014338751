.c-nav {
    position: sticky;
    top: 0;
    display: flex;
    z-index: 20;
    justify-content: space-between;
    padding: var(--space-unit) 0;
    border-top: rem(1) solid var(--color-text-300);

    background: var(--color-brand-blue-700);

    @media (max-width: 900px) {
    padding: var(--space-xs) 0;

        .c-nav__item-list {
            display: none;

            &.visible {
                display: flex;
                flex-direction: column;
                position: absolute;
                right: var(--space-unit);
                top: rem(48);
                background-color: var(--color-brand-blue-700);
                padding: var(--space-xs);
                border-radius: var(--space-xxs);

                .c-nav__item:not(.c-nav__item--cta) {
                    padding-left: 0;
                    width: 100%;

                    &::after {
                        content: none;
                    }
                }

                .c-nav__item--cta {
                    margin-top: var(--space-xs);
                }
            }
        }

        .c-nav__menu-icon {
            display: flex;
        }
    }
}

    .c-nav__inner {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-top: var(--space-xxs);
        padding-bottom: var(--space-xxs);
    }

    .c-nav__item-list {
        display: flex;
        padding: 0;
        margin: 0;
        list-style-type: none;

        li:nth-last-child(2) .c-nav__item {

            &::after {
                content: none;
            }
        }

         @include respond-above(lg) {
            li:last-child .c-nav__item {
                margin-left: 2rem;
            }
        }
    }

    .c-nav__item {
        color: var(--color-white);
        text-decoration: none;
        display: inline-block;
        cursor: pointer;
        padding: var(--space-xs) var(--space-sm) var(--space-xs) var(--space-unit);

        &:not(.c-nav__item--cta)::after {
            content: '>';
            display: inline-block;
            margin-left: 8px;
            transform: rotate(90deg);
        }

        &.active {
            color: var(--color-brand-light-blue);
            
            @include respond-above(sm) {
            }
        }

        @include hocus() {
            color: var(--color-brand-light-blue);
            background-color: var(--color-brand-blue-800);
            
            @media (min-width: 901px) {
                + * {
                    display: block;
                }
            }
        }
    }

    .c-nav__item--cta {
        font-size: .875rem;
        border: 1px solid;
        border-radius: var(--space-xxxs);

        @include hocus() {
            color: var(--color-white);
            background-color: var(--color-brand-blue-600);
            border-color: var(--color-brand-blue-600);
        }
    }

        .c-nav__item-sections {
            display: none;
            position: absolute;
            list-style-type: none;
            background-color: var(--color-brand-blue-800);
            border-radius: var(--space-xxxs);
            padding: 0;

            &:hover {
                display: block;
            }
        }

        .c-subnav__item {
            display: block;
            cursor: pointer;
            color: var(--color-white);
            padding: var(--space-xs) var(--space-unit);
            text-decoration: none;
            border-radius: var(--space-xxxs);

            &[href*="contact"] {
                display: none;
            }

            @include hocus() {
                color: var(--color-white);
                background-color: var(--color-brand-blue-600);
            }
        }

    .c-nav__menu-icon {
        color: white;
        background-color: transparent;
        border: 0;
        height: var(--space-lg);
        width: var(--space-lg);
        align-items: center;
        justify-content: center;
        cursor: pointer;
        display: none;
        padding: 0;

        > svg {
            width: 100%;
            height: 100%;
        }
    }