.c-scanhub-video {
    position: relative;
}

    .c-scanhub-video__btn,
    .c-scanhub-video__btn2 {
        position: absolute;
        width: 80px;
        height: 80px;
        margin: auto;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        border: 0;
        background: rgba(0,0,0,.3);
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        svg {
            width: 100%;
            height: 100%;
        }
    }

    .c-btn--video2 {
        color: var(--color-brand-blue-800);
        border: 1px solid transparent;

        &:hover {
            border: 1px solid white;
            color: white;

            svg {
                fill: white;
            }
        }
    }

    .c-scanhub-video__modal {
        display: none; /* Hidden by default */
        position: fixed; /* Stay in place */
        z-index: 1; /* Sit on top */
        left: 0;
        top: 0;
        width: 100%; /* Full width */
        height: 100%; /* Full height */
        overflow: auto; /* Enable scroll if needed */
        background-color: rgb(0,0,0); /* Fallback color */
        background-color: rgba(0,0,0,0.4);

        .modal-content {
            background-color: #fefefe;
            margin: 15% auto;
            padding: 2rem;
            border-radius: 8px;
            border: 1px solid #888;
            width: 80%;
            max-width: 800px;
            position: relative;
        }

        .close,
        .close2 {
            color: #4a4a4a;
            position: absolute;
            top: .5rem;
            right: .5rem;
            font-size: 32px;
            line-height: 1;
            font-weight: bold;
        }

        .close:hover,
        .close:focus,
        .close2:hover,
        .close2:focus {
            color: #000;
            text-decoration: none;
            cursor: pointer;
        }
    }

    